import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import './subscription.css'

interface SubscriptionCardProps {
  title: string;
  description: string;
  price: number;
  handleClick: () => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ title, description, price, handleClick }) => {
  return (
    <Card className='subscription-card' style={{ borderRadius: '8px'}}>
      <Card.Body className='card-body'>
        <Row className='card-row'>
          <Col xs="auto">
            <Card.Text style={{ color: '#655BAA', fontSize: '1.1rem' }}>MONTHLY</Card.Text>
          </Col>
        </Row>
        <Row className='card-row'>
          <Col xs="auto">
             <Card.Text style={{ color: '#655BAA', fontSize: '3.3rem' }}>{`$ ${Number(price).toFixed(2) } `}</Card.Text>
          </Col>
        </Row>
        <Row className='card-row'>
          <Col xs="auto">
            <Card.Text style={{ color: '#655BAA', fontSize: '0.9rem' }}>PER MONTH</Card.Text>
          </Col>
        </Row>
        <Row className='card-row card-row-btn'>
          <Col xs="auto">
            <Button className='subscribe-btn' onClick={handleClick}>Start for Free!</Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SubscriptionCard;
