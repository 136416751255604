import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { registerReferral } from "../../app/services/ReferralForm/ReferralFormService";
import { useParams } from "react-router-dom";
import { fetchCampaignData } from "../../app/services/Campaign/campaignService";
import { notification } from "antd";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import { isValidPhoneNumber } from "react-phone-number-input";
import OverlayLoader from "../../components/OverlayLoader";
const ReferralForm = () => {
  const { id } = useParams();
  const [logoImage, setLogoImage] = useState(null);
  const [phone, setPhone] = useState<any>("");
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [businessName, setBusinessName] = useState<string>("");
  useEffect(() => {
    const fetchData = () => {
      if (id !== undefined) {
        fetchCampaignData(0, id)
          .then((data) => {
            setBusinessName(data.business_name)
            setLogoImage(data.logo_image);
            if (data.message === "expired") {
              setFetchError("Referral form expired");
            }
          })
          .catch((error) => {
            setFetchError("Wrong URL");
          });
      }
    };
    fetchData();
  }, [id]);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phone)) {
      return notification.error({
        message: "Error",
        description: "Enter valid phone number",
      });
    }

    const formDataToSend = new FormData();
    formDataToSend.append("full_name", formData.fullName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone_number", phone);
    if (id !== undefined) {
      formDataToSend.append("cmp_emp_cli_id", id);
    }
    formDataToSend.append("message", formData.message);
    setLoading(true);

    registerReferral(formDataToSend)
      .then((response) => {
        setLoading(false);
        notification.success({
          message: "Profile Updated",
          description:
            "Thank you! Your information has been submitted, and someone will reach out to you shortly.",
        });

        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
        setPhone("");
      })
      .catch((error) => {
        setLoading(false);
        const responseData: Record<string, any> = error.response?.data || {};
        const errorMessage = responseData.error || "An unknown error occurred";
        notification.error({
          message: "Oops",
          description: errorMessage,
        });
      });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  if (fetchError) {
    return (
      <div className="container">
        <div className="form-wrapper m-5">
          <div style={{ textAlign: "center" }}>
            <div className="alert alert-danger" role="alert">
              <h2>Oops</h2>
              <p>{fetchError}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="form-wrapper m-5">
        <div style={{ textAlign: "center" }}>
          <h2>Referral Form</h2>
          {logoImage && (
            <img
              className="p-4"
              src={`${logoImage}`}
              alt="Logo"
              style={{ width: "200px", height: "auto" }}
            />
          )}
          <h4>{businessName}</h4>
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="m-3" controlId="formGridCampaignDetails">
            <Form.Label>Full name</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
              required
              maxLength={50}
            />
          </Form.Group>
          <Form.Group className="m-3" controlId="formGridReasonForSignUp">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="m-3" controlId="formGridBusinessType">
            <Form.Label>Phone number</Form.Label>
            <PhoneNumber value={phone} setValue={setPhone} />
          </Form.Group>
          <Form.Group className="m-3" controlId="formGridCampaignDetails">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="message"
              placeholder="Any message"
              value={formData.message}
              onChange={handleChange}
              maxLength={300}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="m-3"
            type="submit"
            style={{ float: "right" }}
          >
            Submit
          </Button>
        </Form>
      </div>
      {isLoading ? <OverlayLoader /> : <></>}
    </div>
  );
};
export default ReferralForm;
