import style from './style.module.scss'

const PrivacyPolicy = () => {
  return (
  <section className={style.container}>
     <h1>Privacy Policy </h1>
     <h6>Effective July 10, 2024</h6>
     <div className={style.textContainer}>
    <p>We at YesReferral take your privacy seriously. This Privacy Policy explains 
      how we collect, use and share your information. Please read the following 
      to learn more about our Privacy Policy. By using or accessing the Services 
      in any manner, you acknowledge that you accept the practices and policies 
      outlined in this Privacy Policy, and you hereby consent that we will collect, 
      use, and share your information in the following ways. If you do not agree 
      with our policies and practices, please do not use our Services.</p>
      <p>This Privacy Policy (“Privacy Policy”) is an agreement between you (the 
      “User”, “You” or “Your”) and YesReferral (“YesReferral”, “we”, “us” or “our.”) 
      We use your data to provide and improve the "YesReferral Services," 
      "Service," or "Site" which means any websites, applications or other 
      services of ours that links to this Privacy Policy. By using the Service, you 
      agree to the collection and use of information in accordance with this policy. 
      Unless otherwise defined in this Privacy Policy, terms used in this Privacy 
      Policy have the same meanings as in our Terms Of Service, accessible 
      from www.YesReferral.com.</p>
      <h3>Information Collection And Use </h3>
      <p>We collect several different types of information for various purposes to 
    provide and improve our Service to you. <br />
    Types of Data Collected: 
    </p>
    <h4>Personal Data</h4>
    <p>("Personal Data") means any information that identifies or relates to a 
    particular individual. Our primary purpose for collecting and using Personal 
    Data is to provide Services to our Users. While using our Service, we 
    collect certain personally identifiable information that has been provided to 
    us by our User voluntarily when registering. Personally Data may include, 
    but is not limited to:</p>
    <ul>
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Phone number</li>
      <li>Address, City, State, ZIP/Postal code </li>
      <li>Profile Photos or Business Logos</li>
      <li>Business Name and Address </li>
      <li>Cookies and Usage Data</li>
      <li>Payment Data to process your payments for purchases</li>
      <li>Credentials, such as passwords</li>
    </ul>
    <p>
    We rely on our Users to comply with all applicable privacy laws when 
    collecting, using, or disclosing Personal Information through the Services, 
    including by obtaining appropriate consent (such as from their employees, 
    their clients, or other third parties) prior to collecting, using, and disclosing 
    personal information through the Services. 
    </p>
    <h4>Usage Data</h4>
    <p>
    We may also collect information on how the Service is accessed and used 
    (“Usage Data”). This Usage Data may include information such as your 
    computer’s Internet Protocol address (e.g. IP address), browser type, 
    browser version, the pages of our Service that you visit, the time and date 
    of your visit, the time spent on those pages, unique device identifiers and 
    other diagnostic data.
    </p>
    <h4>Information Collected From Other Sources</h4>
    <p>We may obtain information about you from other sources, such as public 
    databases, joint marketing partners, affiliate programs, data providers, and 
    from other third parties. This information includes mailing addresses, job 
    titles, email addresses, phone numbers, intent data (or user behavior data), 
    Internet Protocol (IP) addresses, social media profiles, social media URLs, 
    and custom profiles.</p>
    <h3>How Do We Process Your Information? </h3>
    <p>
    We process your information to provide, improve, and manage our 
    Services, communicate with you, ensure security and prevent fraud, and 
    comply with legal requirements. With your consent, we may also process 
    your information for other purposes.
    </p>
    <p>
    The reasons for processing your personal information vary based on how 
    you interact with our Services, including:
    </p>
    <ul>
      <li>Account creation and authentication: We process your information to 
    help you create, log in to, and maintain your account.</li>
      <li>Service delivery: We process your information to provide and facilitate 
    the services you request. </li>
    </ul>
    <h3>Links To Other Sites</h3>

    <p>Our Service may contain links to other third party sites that are not 
    operated by us; such as but not limited to, rewards and incentive payouts 
    platforms, social networks, marketing and advertising partners connected 
    to the Service. If you click on a third party link, you will be directed to that 
    third party's site. We strongly advise you to review the Privacy Policy of 
    every site you visit. We have no control over and assume no responsibility 
    for the content, privacy policies or practices of any third party sites or 
    services.</p>
    <h3>When And With Whom Do We Share Your Personal 
    Information? </h3>
    <p>We may share your information in specific situations described in this 
    section and with the following categories of third parties:</p>
    <p>
    <span className={style.boldText}>Vendors, Consultants, and Other Third-Party Service Providers:</span>  We 
    may share your data with third-party vendors, service providers, 
    contractors, or agents ("third parties") who perform services for us or on our 
    behalf and need access to such information to do their work. For example, 
    when using Tremendous for rewards and incentive payouts, you will be 
    redirected to the Tremendous platform, where you will be subject to their 
    Terms and Privacy Policy. Once you return to our site, the data you entered 
    on Tremendous will be updated on our platform. We have contracts with 
    these third parties to help safeguard your personal information, ensuring 
    they only process it according to our instructions and protect it 
    appropriately.
    </p>
    <p>The categories of third parties we may share personal information with 
    include:</p>
    <ul>
      <li>Payment Processors</li>
      <li>Communication & Collaboration Tools</li>
      <li>Website Hosting Service Providers</li>
      <li>Rewards and Incentive Payouts Platforms </li>
    </ul>
    <p>
      <span className={style.boldText}>Business Transfers: </span>
      We may share or transfer your information in 
    connection with, or during negotiations of, any merger, sale of company 
    assets, financing, or acquisition of all or a portion of our business to 
    another company.
    </p>
    <p>
      <span className={style.boldText}>Affiliates: </span>
      We may share your information with our affiliates, requiring them 
    to honor this privacy notice. Affiliates include our parent company and any 
    subsidiaries, joint venture partners, or other companies we control or that 
    are under common control with us.
    </p>
    <p>
      <span className={style.boldText}>Business Partners: </span>
      We may share your information with our business 
    partners to offer you certain products, services, or promotions.
    </p>
    <h3>Payments</h3>
    <p>
    We provide paid products and/or services within the Service. In that case, 
    we may use third-party services, Stripe (<a href="https://stripe.com/" target="_blank">stripe.com</a> ), for payment 
    processing (e.g. payment processors). <br />
    We will not store Your payment card details. That information is provided 
    directly to Our third-party payment processors whose use of Your personal 
    information is governed by their Privacy Policy. These payment processors 
    adhere to the standards set by PCI-DSS as managed by the PCI Security 
    Standards Council, which is a joint effort of brands like Visa, Mastercard, 
    American Express and Discover. PCI-DSS requirements help ensure the 
    secure handling of payment information.
    </p>
    <h3>How Long Do We Keep Your Information?</h3>
    <p>We retain your information for as long as necessary to fulfill the purposes 
    outlined in this privacy notice, unless a longer retention period is required 
    or permitted by law (such as for tax, accounting, or other legal 
    requirements). We will not keep your personal information for longer than 
    the period in which you have an active account with us.</p>
    <p>
    When we no longer have a legitimate business need to process your 
    personal information, we will delete or anonymize it. If deletion or 
    anonymization is not possible (for example, if your information is stored in 
    backup archives), we will securely store your personal information and 
    isolate it from any further processing until deletion is possible.
    </p>
    <h3>Transfer of Data</h3>
    <p>Your information, including Personal Data, may be transferred to and 
    maintained on computers located outside of your state, province, country, 
    or other governmental jurisdiction where the data protection laws may differ 
    from those in your jurisdiction. <br />If you are located outside the United States and choose to provide 
    information to us, please note that we transfer the data, including Personal 
    Data, to the United States and process it there. By agreeing to this Privacy Policy and submitting your information, you
    consent to the transfer of your Personal Data to the United States. <br />YesReferral will take all steps reasonably necessary to ensure that your 
    data is treated securely and in accordance with this Privacy Policy. We will 
    not transfer your Personal Data to any organization or country unless there 
    are adequate controls in place, including the security of your data and other 
    personal information.</p>
    <h3>How Do We Keep Your Information Safe? </h3>
    <p>We aim to protect your personal information through a system 
    of organizational and technical security measures. However, despite our 
    safeguards and efforts to secure your information, no electronic 
    transmission over the Internet or information storage technology can be 
    guaranteed to be 100% secure, so we cannot promise or guarantee that 
    hackers, cybercriminals, or other unauthorized third parties will not be able 
    to defeat our security and improperly collect, access, steal, or modify your 
    information. Although we will do our best to protect your personal 
    information, transmission of personal information to and from our Services 
    is at your own risk. You should only access the Services within a secure 
    environment.</p>
    <h3>Children's Privacy</h3>
    <p>
    The Site and YesReferral Services are intended solely for persons who are 
    age 18 years of age or older. Any access to or use of the YesReferral 
    Services by anyone under age 18 is expressly prohibited. By accessing or 
    using the YesReferral Services you represent and warrant that you are 18 
    years of age or older. If we learn that personal information from users less 
    than 18 years of age has been collected, we will deactivate the account 
    and take reasonable measures to promptly delete such data from our 
    records. If you become aware of any data we may have collected from 
    children under age 18, please contact us at <a href="mailto:support@yesreferral.com">support@yesreferral.com</a> . 
    </p>
    <h3>What Are Your Privacy Rights? </h3>
    <p>
    You have the right to review, change, or terminate your account at any time, 
    depending on your country, province, or state of residence. <br />
    Withdrawing your consent: If we rely on your consent to process your 
    personal information, you can withdraw this consent at any time. Consent 
    may be express or implied, depending on the applicable law. To withdraw 
    your consent, contact us using the details provided in the "HOW CAN YOU 
    CONTACT US ABOUT THIS NOTICE?" section below. <br />
    Please note that withdrawing your consent does not affect the lawfulness of 
    any processing conducted before your withdrawal. Additionally, where 
    applicable law allows, it does not affect the processing of your personal 
    information based on lawful grounds other than consent.
    </p>
    <h3>Closing Account</h3>
    <p>If you choose to close your account you may do so by accessing your 
    Account page from your account to Cancel your account. After you cancel 
    your account, although you will no longer be billed, you will still have 
    access to the Services until the end of the current subscription term. At the 
    end of your subscription term, you will not be able to sign in or access any 
    of your information. <br />
    Once you cancel your YesReferral account, we have no obligation to retain 
    your information and may delete any or all of your account information 
    without liability. Information may be moved out of our active databases or 
    may still be retained in our systems to reinstate your membership with our 
    services at your request, prevent fraud, troubleshoot problems, assist with 
    any investigations, enforce our Terms of Use and/or comply with legal 
    requirements. <br />
    You acknowledge that if you have provided or shared information to third 
    party users, retention of that information will be subject to those third 
    parties policies and practices as described in these Terms.
    </p>
    <h3>Controls For DO-NOT-TRACK Features</h3>
    <p>
    Most web browsers and some mobile operating systems and mobile 
    applications include a Do-Not-Track ("DNT") feature or setting you can 
    activate to signal your privacy preference not to have data about your 
    online browsing activities monitored and collected. At this stage, no uniform 
    technology standard for recognizing and implementing DNT signals has 
    been finalized. As such, we do not currently respond to DNT browser 
    signals or any other mechanism that automatically communicates your 
    choice not to be tracked online. If a standard for online tracking is adopted 
    that we must follow in the future, we will inform you about that practice in a 
    revised version of this privacy notice.
    </p>
    <p>
    California law requires us to let you know how we respond to web browser 
    DNT signals. Because there currently is not an industry or legal standard 
    for recognizing or honoring DNT signals, we do not respond to them at this 
    time.
    </p>
    <h3>Specific Rights For United States Residents</h3>
    <p>
    If you are a resident of California, Colorado, Connecticut, Delaware, 
    Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, 
    Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to 
    request access to and receive details about the personal information we 
    maintain about you and how we have processed it, correct inaccuracies, 
    get a copy of, or delete your personal information. You may also have the 
    right to withdraw your consent to our processing of your personal 
    information. These rights may be limited in some circumstances by 
    applicable law. More information is provided below.
    </p>
    <h3>Categories of Personal Information We Collect</h3>
    <p>In the past twelve (12) months, we may have collected the following 
    categories of personal information:</p>
    <ul>
      <li>
      Category A: Identifiers. <br />
    Examples: Real name, alias, postal address, unique personal identifier, 
    online identifier, IP address, email address, account name, driver's 
    license number, passport number, or other similar identifiers. <br />
    Collected: <span className={style.boldText}>Yes</span>.
      </li>
      <li>
      Category B: Personal information categories listed in the California 
    Customer Records statute (Cal. Civ. Code § 1798.80(e)).
    Examples: Name, signature, Social Security number, physical 
    characteristics, address, telephone number, passport number, driver's 
    license number, insurance policy number, education, employment 
    history, bank account number, credit card number, or other financial 
    information. Some personal information in this category may overlap with 
    other categories. <br />
    Collected: <span className={style.boldText}>Yes</span>.
      </li>
      <li>
      Category C: Protected classification characteristics under California or 
    federal law.
    <br />
    Examples: Age (40 years or older), race, color, ancestry, national origin, 
    citizenship, religion, marital status, medical condition, physical or mental 
    disability, sex, sexual orientation, veteran or military status, genetic 
    information. <br />
    Collected: <span className={style.boldText}>No</span>.
      </li>
      <li>
        Category D: Commercial information.
        <br />
        Examples: Records and history of products or services purchased or 
        considered. <br />
        Collected: <span className={style.boldText}>Yes</span>.
      </li>
      <li>
        Category E: Biometric information.
        <br />
        Examples: Genetic, physiological, behavioral, and biological 
        characteristics, fingerprints, faceprints, voiceprints, iris or retina scans, 
        keystroke, gait, or other physical patterns, sleep, health, or exercise 
        data. <br />
        Collected: <span className={style.boldText}>No</span>.
      </li>
      <li>
      Category F: Internet or other similar network activity.
      <br />
      Examples: Interaction with our Service or advertisement. <br />
      Collected: <span className={style.boldText}>Yes</span>.
      </li>
      <li>
      Category G: Geolocation data.
      <br />
      Examples: Approximate physical location.<br />
      Collected: <span className={style.boldText}>No</span>.
      </li>
      <li>
      Category H: Sensory data.
      <br />
      Examples: Audio, electronic, visual, thermal, olfactory, or similar 
      information.<br />
      Collected: <span className={style.boldText}>No</span>.
      </li>
      <li>
        Category I: Professional or employment-related information.
      <br />
      Examples: Current or past job history or performance evaluations.<br />
      Collected: <span className={style.boldText}>No</span>.
      </li>
      <li>
      Category J: Non-public education information.
      <br />
      Examples: Education records directly related to a student maintained by 
      an educational institution or party acting on its behalf, such as grades, 
      transcripts, class lists, student schedules, student identification codes, 
      student financial information, or student disciplinary records.<br />
      Collected: <span className={style.boldText}>No</span>.
      </li>
      <li>
        Category K: Inferences drawn from other personal information.
        <br />
        Examples: Profile reflecting preferences, characteristics, psychological 
        trends, predispositions, behavior, attitudes, intelligence, abilities, and 
        aptitudes.<br />
        Collected: <span className={style.boldText}>No</span>.
      </li> 
    </ul>
    <p>Personal information does not include:</p>
    <ul>
        <li>Publicly available information from government records </li>
        <li>De-identified or aggregated consumer information </li>
        <li>Information excluded from the CCPA's scope, such as:</li>

        <li>Health or medical information covered by HIPAA and the California 
             Confidentiality of Medical Information Act (CMIA) or clinical trial data</li>

        <li>Personal Information covered by sector-specific privacy laws, including 
            the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act 
            (GLBA) or California Financial Information Privacy Act (FIPA), and the 
            Driver's Privacy Protection Act of 1994</li>
        </ul>

    <h3>Sources of Personal Information </h3>
    <p>We obtain the categories of personal information listed above from the 
      following sources:</p>
      <ul>
        <li>
        Directly from you (e.g., forms you complete on our Service, preferences 
         you express, purchases).
        </li>
        <li>
        Indirectly from you (e.g., observing your activity on our Service).
        </li>
        <li>
        Automatically from you (e.g., cookies set on your device).
        </li>
        <li>
        From service providers (e.g., third-party vendors for payment processing 
        or service provision).
        </li>
      </ul>
    
    <h3>Use of Personal Information for Business or Commercial 
         Purposes </h3>
    <p>
    We may use or disclose personal information for "business purposes" or 
    "commercial purposes" as defined under the CCPA, including:
    </p>
    <ul>
      <li>
      Operating our Service and providing you with our Service.
      </li>
      <li>
      Responding to your inquiries and providing support.
      </li>
      <li>
      Fulfilling the reasons you provided the information (e.g., processing 
       payments, facilitating delivery). 
      </li>
      <li>
      Responding to law enforcement requests and as required by law.
      </li>
      <li>
      For internal administrative and auditing purposes. 
      </li>
      <li>
      Detecting security incidents and protecting against malicious, deceptive, 
      fraudulent, or illegal activity.
      </li>
      <li>
      Disclosure of Personal Information for Business or Commercial 
      Purposes
      </li>
    </ul>
    <p>
    We may have disclosed the following categories of personal information for 
    business or commercial purposes in the past twelve (12) months:
    </p>
    <ul>
      <li>
      Category A: Identifiers
      </li>
      <li>
      Category B: Personal information categories listed in the California 
       Customer Records statute (Cal. Civ. Code § 1798.80(e))
      </li>
      <li>
      Category D: Commercial information 
      </li>
      <li>
      Category F: Internet or other similar network activity
      </li>
    </ul>
    <p>
    We enter contracts that describe the purpose and require the recipient to 
    keep the personal information confidential and not use it for any purpose 
    except performing the contract.
    </p>

    <h3>Your Rights</h3>
    <p>
    You have rights under certain US state data protection laws. However, 
   these rights are not absolute, and in certain cases, we may decline your 
    request as permitted by law. These rights include: 
    </p>
    <ul>
      <li><span className={style.boldText}>Right to know</span> whether or not we are processing your personal data</li>
      <li><span className={style.boldText}>Right to access</span> your personal data</li>
      <li><span className={style.boldText}>Right to correct</span> inaccuracies in your personal data</li>
      <li><span className={style.boldText}>Right to request</span> the deletion of your personal data</li>
      <li><span className={style.boldText}>Right to obtain a copy</span> of the personal data you previously shared with us</li>
      <li><span className={style.boldText}>Right to non-discrimination</span> for exercising your rights</li>
      <li><span className={style.boldText}>Right to opt out</span> of the processing of your personal data if it is 
      used for targeted advertising (or sharing as defined under 
      California's privacy law), the sale of personal data, or profiling in 
      furtherance of decisions that produce legal or similarly significant 
      effects ("profiling")</li>
    </ul>
    <p>Depending upon the state where you live, you may also have the following 
       rights:</p>
    <ul>
      <li>
      Right to obtain a list of the categories of third parties to which we 
      have disclosed personal data (as permitted by applicable law, 
      including California's and Delaware's privacy law)
      </li>
      <li>
      Right to obtain a list of specific third parties to which we have 
      disclosed personal data (as permitted by applicable law, 
      including Oregon's privacy law)
      </li>
      <li>
      Right to limit use and disclosure of sensitive personal data (as 
       permitted by applicable law, including California's privacy law)
      </li>
      <li>
      Right to opt out of the collection of sensitive data and personal 
      data collected through the operation of a voice or facial 
      recognition feature (as permitted by applicable law, including 
      Florida's privacy law) 
      </li>

    </ul>

    <h3>How to Exercise Your Rights</h3>
    <p>
    To exercise these rights, you can contact us by emailing us 
    at <a href="mailto:support@yesreferral.com">support@yesreferral.com</a> , or by referring to the contact details at the 
    bottom of this document.
    Under certain US state data protection laws, you can designate 
    an authorized agent to make a request on your behalf. We may deny a 
    request from an authorized agent that does not submit proof that they have 
    been validly authorized to act on your behalf in accordance with applicable 
    laws.
    </p>

    <h3>Request Verification</h3>
    <p>
    Upon receiving your request, we will need to verify your identity to 
    determine you are the same person about whom we have the information 
    in our system. We will only use personal information provided in your 
    request to verify your identity or authority to make the request. However, if 
    we cannot verify your identity from the information already maintained by 
    us, we may request that you provide additional information for the purposes 
    of verifying your identity and for security or fraud-prevention purposes.
    <br />
    If you submit the request through an authorized agent, we may need to 
    collect additional information to verify your identity before processing your 
    request and the agent will need to provide a written and signed permission 
    from you to submit such request on your behalf.
    </p>
    <h3>
      Appeals
    </h3>
    <p>
    Under certain US state data protection laws, if we decline to take action 
    regarding your request, you may appeal our decision by emailing us 
    at <a href="mailto:support@yesreferral.com">support@yesreferral.com</a> . We will inform you in writing of any action 
    taken or not taken in response to the appeal, including a written 
    explanation of the reasons for the decisions. If your appeal is denied, you 
    may submit a complaint to your state attorney general.
    </p>

    <h3>California "Shine The Light" Law</h3>
    <p>
    California Civil Code Section 1798.83, also known as the "Shine The 
    Light" law, permits our users who are California residents to request and 
    obtain from us, once a year and free of charge, information about 
    categories of personal information (if any) we disclosed to third parties for 
    direct marketing purposes and the names and addresses of all third parties 
    with which we shared personal information in the immediately preceding 
    calendar year. If you are a California resident and would like to make such 
    a request, please submit your request in writing to us by using the contact 
    details provided in the section : ”How You Can Contact Us About This 
    Notice.”
    </p>
    <h3>
    Changes To This Privacy Policy
    </h3>
    <p>
    We may update this privacy notice from time to time. The updated version 
    will be indicated by an updated "Revised" date at the top of this privacy 
    notice. If we make material changes to this privacy notice, we may notify 
    you either by prominently posting a notice of such changes or by directly 
    sending you a notification. We encourage you to review this privacy notice 
    frequently to be informed of how we are protecting your information. By 
    continuing to utilize the Services after any such changes are posted, you 
    will agree and consent to any such changes.
    </p>
    <h3>
    How Can You Contact Us About This Notice?
    </h3>
    <p>
    If you have questions or comments about this notice, you may email us 
     at <a href="mailto:support@yesreferral.com ">support@yesreferral.com</a>  or contact us by mail at: <br /><br />
     YesReferral <br />
     PO Box 18532 <br />
     Atlanta, GA 31126 <br />
    </p>

    </div>
 </section>)
};

export default PrivacyPolicy;
