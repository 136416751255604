import pricingStyle from './style.module.scss';
import style from '../LandingPage/style.module.scss'
import paintBrushBg from '../../images/paintBrush.png';
import { FaCircleCheck } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Pricing = () => {
  return <div className={pricingStyle.pricingContainer}>
  <div>
    <div className={pricingStyle.textContainer}>
      <h1 className={pricingStyle.mainTxt}>Simple, straight forward pricing</h1>
      <h3 className={pricingStyle.subTxt}>Try free for 7 days! No contracts <span className={style.paintBrushBg}><img src={paintBrushBg} alt="" /> <span className={style.paintBrushTxt}>cancel anytime.</span> </span> </h3>
    </div>
  </div>
  
  <div className={pricingStyle.heroContainer}>
    <div className={pricingStyle.leftContainer}>
      <div className={pricingStyle.textContainer}>
       <h5>$49<span className={pricingStyle.subTxt}>/mo</span></h5> 
       <Link to="/user/registration"> <button className={pricingStyle.signupButton}>Start For Free!</button></Link>
      </div>
    </div>
    <div className={pricingStyle.rightContainer}>
      <div className={pricingStyle.LinksContainer}>
        <div className={pricingStyle.socialLinkElm}>
          <div>
            <FaCircleCheck className={pricingStyle.Icon}/>
          </div>
          <p>Launch unlimited referral campaigns</p>
        </div>
        <div className={pricingStyle.socialLinkElm}>
          <div>
            <FaCircleCheck className={pricingStyle.Icon}/>
          </div>
          <p>Campaign management and referral tracking</p>
        </div>
        <div className={pricingStyle.socialLinkElm}>
          <div>
            <FaCircleCheck className={pricingStyle.Icon}/>
          </div>
          <p>Grow a database of new leads</p>
        </div>
        <div className={pricingStyle.socialLinkElm}>
            <div>
              <FaCircleCheck className={pricingStyle.Icon}/>
            </div>
            <p>Reward your referrers with 100+ gift card and charitable choices</p>
        </div>
      </div>
    </div>
  </div>
  </div>;
};

export default Pricing;
