import React from 'react'
import ProfileTabs from './components/ProfileTabs';

type Props = {}

const Profile = (props: Props) => {
  return (
      <>
          <h2 style={{ margin: '1.9rem' }}>Account Settings</h2>
          <div style={{ margin: '1.9rem' }}>
              <ProfileTabs />
          </div>
      </>
  )
}

export default Profile;