import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

interface HeaderProps {
  toggleDrawer: () => void;
}

function Header({ toggleDrawer }: HeaderProps) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' });

  return (
    <Navbar className="bg-body-tertiary" style={{ backgroundColor: '#331D66' }}>
      <Container >
        {/* <Navbar.Brand>Welcome To YesReferral</Navbar.Brand> */}
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {/* <Navbar.Text>
            Signed in as: <a href="#login">Aanal Trivedi</a>
          </Navbar.Text> */}
        </Navbar.Collapse>
        {isTabletOrMobile && (
          <Button
            type="primary" className='btn-primary'
            onClick={toggleDrawer}
            icon={<MenuOutlined />}
            style={{ marginLeft: 'auto' }}
          />
        )}
      </Container>
    </Navbar>
  );
}

export default Header;
