import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { CancelSubscriptionModalProps } from './CancelSubscriptionModalProps';
import '.././SubscriptionSettings.css';

const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({show, handleClose, handleCancel}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Cancel Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to cancel your subscription?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button className='confirm-cancel-subscribe-btn'
          onClick={handleCancel}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CancelSubscriptionModal;