import React from "react";
import { Spinner } from "react-bootstrap";

const OverlayLoader = () => {
  return <div style={{backgroundColor:"rgba(255,255,255,0.8)"}} 
  className="d-flex justify-content-center align-items-center w-100 vh-100 position-fixed fixed-top">
<Spinner style={{color:"#655baa"}} animation="border" role="status" ><span className="sr-only"></span></Spinner>
</div>;
};

export default OverlayLoader;
