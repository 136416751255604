import React from 'react';
import { useLocation } from 'react-router-dom';

type Props = {}

const Footer = (props: Props) => {
  const location = useLocation();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {location.pathname === '/' && (
          <div style={{ textAlign: 'center', position: 'fixed', bottom: 20 }}>
            @All rights reserved {new Date().getFullYear()}
          </div>
        )}
      </div>
    </>
  );
};

export default Footer;
