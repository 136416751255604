import React from 'react';
import { Modal } from 'react-bootstrap';
import { formatDateDefault } from '../../utils/functions/timeConversion'; 

interface DetailsModalProps {
  show: boolean;
  handleClose: () => void;
  data: any;
  display: 'campaign' | 'referral';
}

const DetailsModal: React.FC<DetailsModalProps> = ({ show, handleClose, data, display }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{display === 'campaign' ? 'Campaign Details' : 'Referral Details'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data && display === 'campaign' && (
          <>
            <p><strong>Campaign Name:</strong> {data.campaign_name}</p>
            <p><strong>Campaign Guidelines:</strong> {data.campaign_guidelines}</p>
            {data.referred_type === 'Employee' && (
              <p><strong>Job Opening Details:</strong> {data.job_opening_details}</p>
            )}
            <p><strong>Campaign End Date:</strong> {formatDateDefault(data.campaign_end_date)}</p>
            <p><strong>Reward Amount:</strong> {data.reward_amount}</p>
          </>
        )}
        {data && display === 'referral' && (
          <>
            <p><strong>Name:</strong> {data.referred_name}</p>
            <p><strong>Email:</strong> {data.email}</p>
            <p><strong>Phone Number:</strong> {data.phone_number}</p>
            <p><strong>Referred Date:</strong> {formatDateDefault(data.referred_date)}</p>
            <p><strong>Message:</strong> {data.message}</p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DetailsModal;
