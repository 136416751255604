import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store/store';
import { logout, initializeAuth } from '../app/features/auth/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { USER_REGISTRATION_LOGIN_API } from './constants/apis.urls';
import { axiosInstance } from '../config/interceptor';
import jwtDecode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

interface DecodedToken {
  exp: number;
}

export const isTokenExpired = (token: string): boolean => {
  if (!token) return true;
  const { exp } = jwtDecode<DecodedToken>(token);
  return exp * 1000 < Date.now();
};

const TokenMonitor = () => {
  const isAuthenticated = Cookies.get('userin') !== undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { access, refresh, user } = useSelector((state: RootState) => state.auth);
  const [loginTime, setLoginTime] = useState<number | null>(null);
  const location = useLocation();
  const { pathname } = location;


  useEffect(() => {
    if(!!isAuthenticated){
      // Function to refresh access token when needed
    const checkTokenExpiry = async () => {
      const storedRefreshToken = localStorage.getItem('refreshToken') || refresh;

      if (isTokenExpired(access)) {
        try {

          const response = await axiosInstance.post(USER_REGISTRATION_LOGIN_API.FETCH_REFRESH_CODE, { refresh: storedRefreshToken });
          const newAccessToken = response.data.access;

          dispatch(initializeAuth({ access: newAccessToken, refresh: storedRefreshToken, user }));
          localStorage.setItem('accessToken', newAccessToken);
        } catch (error) {
          console.error('Failed to refresh access token:', error);
          dispatch(logout());
          navigate('/user/login');
        }
      }

      // Check logout after 24 hours of login
      if (loginTime) {
        const oneDayInMillis = 24 * 60 * 60 * 1000; // 1 day in milliseconds
        // EmailVerification
        const currentTime = Date.now();

        if (currentTime - loginTime >= oneDayInMillis) {
          dispatch(logout());
          navigate('/user/login');
        }
      }
    };

    // Set login time when user logs in or refreshes token
    setLoginTime(Date.now());

    // Check token expiry and logout after 24 hours on interval
    const intervalId = setInterval(() => {
      checkTokenExpiry();
    }, 1000 * 60); // Check every minute

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
    }
  
  // eslint-disable-next-line 
  }, [access, refresh, user, dispatch, navigate,pathname]);

  return null; // or whatever JSX you may need
};

export default TokenMonitor;
