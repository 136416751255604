import { Col, Container, Row } from 'react-bootstrap';
import { checkoutSession } from '../../app/services/Subscription/checkoutSession';
import SubscriptionCard from './SubscriptionCard';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store/store';
import { User } from '../../app/features/auth/AuthSlice';


const SubscriptionForm: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const userDetails = user as User;

  const public_stripe_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_TEST_KEY;
  const stripePromise: Promise<Stripe | null> = loadStripe(public_stripe_key || "");

  const handleSubscribe = async () => {
      checkoutSession().then((res) => {
        const session = res.data;
          stripePromise.then((stripe) => {
            if (stripe) {
              stripe.redirectToCheckout({
                sessionId: session.id,
              });
            }
          });
      }).catch((error) => {
        console.log("error", error);
      });
  }

  return (
    <>
      {
        !['active', 'trialing'].includes(userDetails?.subscription_status) ?
          <>
            <h1 style={{ fontWeight: 700, textAlign: 'center' }}>
              <span style={{ color: "#655BAA" }}>Simple Pricing</span> For Your Small Business
            </h1>
            <h4 style={{ textAlign: 'center' }}>
                Try free for 7 days! No contracts, cancel anytime.
            </h4>
            <Container className="d-flex justify-content-center" style={{ marginTop: '6rem' }}>
              <Row>
                <Col>
                  <SubscriptionCard
                    title="YesReferral"
                    description="Enjoy our services on a monthly basis."
                    price={49}
                    handleClick={() => handleSubscribe()}
                  />
                </Col>
              </Row>
            </Container>
          </> : null
      }
    </>
    )
};

export default SubscriptionForm;