import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../app/services/UserServices";
import { ResetPasswordData } from "./ResetPasswordType";
import { notification } from 'antd';
import Spinner from "../../components/Spinner";
import { Link } from 'react-router-dom';
import logo from '../../images/yes-referral-logo.png';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import '../../style/common.css';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const newPassword = e.currentTarget.password.value;
    const confirmPassword = e.currentTarget['confirm-password'].value;
    if (newPassword !== confirmPassword) {
      setIsLoading(false);
      return showNotification('error', 'Passwords do not match.');
    }

    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setIsLoading(false);
      return showNotification('error', 'Password should be at least 8 characters long and include at least one number, one special character, one uppercase letter, and one lowercase letter.');
    }

    const data: ResetPasswordData = {
      password: newPassword,
      email: searchParams.get("value"),
      session_token: searchParams.get("token"),
      security_code: searchParams.get("token"),
    };

    resetPassword(data).then((res: any) => {
      setIsLoading(false);
      if (res.status === 202) {
        e.target.reset();
        navigate("/user/login");
      } else {
        if(res.password){
          setErrorMsg(res.password);
        } 
        else if (res.non_field_errors) {
          showNotification('error', res.non_field_errors[0]);
        }
      }
    }).catch((error:any)=>{
      setIsLoading(false)
      showNotification('error', String(error));
    })
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showNotification = (type: 'success' | 'error', message: string) => {
    notification[type]({
      message: type === 'success' ? 'Success' : 'Oops!',
      description: message,
    });
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4 border rounded shadow-sm border-gray" style={{ width: '100%', maxWidth: '30rem' }}>
        <div className="mb-4">
          <img className='pb-4' src={logo} alt="Logo" style={{ width: '234px', height: 'auto' }} />
          <h2>Reset Password</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">New Password</label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                required
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  backgroundColor: 'transparent',
                  border: '1px solid #655baa',
                  color: '#655baa',
                }}
              >
                {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
            {errorMsg && <div className="input-error">{errorMsg}</div>} {/* Display error message */}
          </div>
          <div className="mb-3">
            <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
            <input type="password" className="form-control" id="confirm-password" name="confirm-password" required />
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">Reset Password</button>
            {isLoading && <Spinner />}
          </div>
        </form>
        <div className="mt-3 text-center text-gray">
          <p>Remember your password? <Link to="/user/login" className="text-decoration-none link">Log in</Link></p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
