import style from './style.module.scss';
import logo from '../../images/yes-referral-logo.png';
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { Button,Tooltip } from 'antd';
import { MenuOutlined,HomeOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import Cookies from 'js-cookie';


const PublicLayout = () => {
  const isPhoneScreen = useMediaQuery({
    query: '(max-width: 768px)'
  })
    const [open, setOpen] = useState(false);
    const [isFAQ,setIsFAQ]=useState(false)
    const {pathname}=useLocation();
    const isAuthenticated = Cookies.get('userin') !== undefined;
    const showDrawer = () => {
        setOpen(true);
      };
    
      const onClose = () => {
        setOpen(false);
      };
      useEffect(()=>{
        window.scrollTo(0, 0);
        if(pathname==="/faq"|| pathname==="/contact" || pathname==="/terms-conditions" || pathname==="/privacy-policy"){
          setIsFAQ(true)
          
        }else{
          setIsFAQ(false)
        }
  
      },[pathname])
  return <main className={style.main}>

 <nav className={style.nav}>
    <NavLink to="/">
      <div className={style.logo}>
          <img src={logo} alt="Logo" />
      </div>
      </NavLink>
      {
        isFAQ && isAuthenticated?<NavLink to="/">
          {
            isPhoneScreen?   <Tooltip title="go to Dashboard">
            <Button className='btn-primary' shape="circle" icon={<HomeOutlined />} />
          </Tooltip>: <Button className="btn-primary rounded-5 font-weight-bold" size="large">Go to Dashboard</Button>
          }
          </NavLink>
    :<>   <div className={style.navLeftSection}>
    <NavLink to="/pricing" className={({isActive})=>isActive?`${style.selectedLink} ${style.navLink}`:style.navLink}>Pricing</NavLink>
    <NavLink to="/faq" className={({isActive})=>isActive?`${style.selectedLink} ${style.navLink}`:style.navLink}>FAQ</NavLink>
    <NavLink to="/user/login" className={({isActive})=>isActive?`${style.selectedLink} ${style.navLink}`:style.navLink}>Login</NavLink>
    <NavLink to="/pricing"> <Button className="btn-primary rounded-5 font-weight-bold" size="large">Start for Free!</Button></NavLink>
   

</div>
<Button className={`btn-primary ${style.menu}`} style={{fontFamily:"Poppins"}} shape='circle' type="primary" icon={<MenuOutlined />} onClick={showDrawer} /></>
      }
   
      <Drawer onClose={onClose} open={open} placement={"left"}>
      <div className={style.MenuMobile}>
          <NavLink to="/pricing" className={({isActive})=>isActive?`${style.selectedLink} ${style.navLink}`:style.navLink} onClick={onClose}>Pricing</NavLink>
          <NavLink to="/faq" className={({isActive})=>isActive?`${style.selectedLink} ${style.navLink}`:style.navLink} onClick={onClose}>FAQ</NavLink>
          <NavLink to="/user/login" className={({isActive})=>isActive?`${style.selectedLink} ${style.navLink}`:style.navLink} onClick={onClose}>Login</NavLink>
          <NavLink to="user/registration" className={style.buttonWrapper} onClick={onClose}> <Button className="btn-primary rounded-5 font-weight-bold" size="large">Start for Free!</Button></NavLink>
         

      </div>
      </Drawer>
         
  </nav>
  <Outlet></Outlet>
  <footer className={style.footer}>
    <div className={style.footerLinksContainer}>
        <Link to="/contact" className={style.footerLink}>Contact Us</Link>
        <Link to="/terms-conditions" className={style.footerLink}>Terms & Conditions</Link>
        <Link to="/faq" className={style.footerLink}>FAQ</Link>
        <Link to="/privacy-policy" className={style.footerLink}>Privacy Policy</Link>
    </div>
    <div className={style.socialLinksContainer}>
      <a href="https://www.instagram.com/yesreferral/" target='_blank'>
      <FaInstagram className={style.socialIcon}/> 
      </a>
      <a href="https://www.facebook.com/Get.YesReferral.com" target='_blank'>
      <FaFacebookF className={style.socialIcon}/>
      </a>
      <a href="https://x.com/YesReferral" target='_blank'>
      <FaTwitter className={style.socialIcon} /> 
      </a>
      <a href="https://www.linkedin.com/company/yesreferral/" target='_blank'>
      <FaLinkedin className={style.socialIcon}/>
      </a>     
         
    </div>
    <p className={style.copywriteText}>&#169; 2024 YesReferral, LLC   All Rights Reserved</p>

  </footer>

 
</main>;
};

export default PublicLayout;
