import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTremendousOAuth } from '../../app/services/Reward/OAuthService';
import { setAuthorizationCode } from '../../app/features/auth/AuthSlice';
import { handleUserLogin } from '../../utils/functions/handleUserLogin';
import { LoginData } from '../Login/LoginType';

const RedirectTremendous: React.FC = () => {
  const tremendousRedirecting = Cookies.get('tremendous_redirecting') || '';
  const email = Cookies.get('user_email') || '';
  // const isAuthenticated = !!Cookies.get('userin');

  const [isCode, setIsCode] = useState(false);
  const [isError, setError] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // const user = useSelector((state: RootState) => state.auth.user);
  const userData: LoginData = { email, password: "dummy value", refreshFlag: true };

  useEffect(() => {
    const handleOAuth = async (code: string) => {
      try {
        await getTremendousOAuth(code);
        await handleUserLogin(dispatch, userData);
        
        Cookies.remove("tremendous_redirecting");
        dispatch(setAuthorizationCode({ authorization_code: code }));
        navigate("/");
      } catch (error) {
        console.error('Error during OAuth handling:', error);
        setError(true);
      }
    };

    const code = searchParams.get('code');

    if (code) {
      setIsCode(true);
      Cookies.set("tremendous_redirecting", code);
      handleOAuth(code);
    } else if (tremendousRedirecting) {
      handleOAuth(tremendousRedirecting);
    }
    // eslint-disable-next-line 
  }, [searchParams, dispatch, userData, navigate]);

  const handleRedirect = () => {
    window.open(`https://app.testflight.tremendous.com/oauth/authorize?client_id=${process.env.REACT_APP_TREMENDOUS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_TREMENDOUS_REDIRECT_URI}&response_type=code`, '_blank');
  };

  return (
    <main className="w-100 d-flex justify-content-center align-items-center flex-column" style={{ minHeight: '100vh' }}>
      {isCode || tremendousRedirecting ? (
        isError ? (
          <div className="alert alert-danger" role="alert">
            There's some problem while creating your session. Please log in and try again later.
          </div>
        ) : (
          <p>Please wait while we are redirecting you to the dashboard page</p>
        )
      ) : (
        <div className="special-page" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <div style={{ color: '#655baa', textAlign: 'center', maxWidth: '900px', padding: '30px', background: '#ffffff', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 31px', borderRadius: '10px' }}>
          <h2 style={{ color: '#655baa', marginBottom: '30px', fontFamily: 'Arial, sans-serif' }}>Welcome to YesReferral!</h2>
          <Alert variant="" style={{ border: '2px solid #655baa', borderRadius: '10px', padding: '20px', marginBottom: '30px', textAlign: 'left', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
            <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
              Next, go to Tremendous to complete your account setup.
            </p>
            <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
              Tremendous is a trusted third-party provider we partner with, used by businesses like Google and Amazon. They offer gift card rewards from brands such as Visa, Amazon, along with a variety of stores and restaurants, allowing you to securely issue rewards to clients and employees.
            </p>
            <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
              In order to integrate Tremendous with YesReferral, you will need to complete the following actions on Tremendous.com:
            </p>
            <ol style={{ fontSize: '16px', lineHeight: '1.6', paddingLeft: '20px' }}>
              <li>Create an account or login to your existing account.</li>
              <li>Add a billing method (funding source) for the rewards you issue.</li>
            </ol>
          </Alert>
          <Button variant="primary" style={{ backgroundColor: '#655baa', border: 'none', borderRadius: '5px', padding: '10px 20px', fontSize: '18px' }} onClick={handleRedirect}>
            Go To Tremendous
          </Button>
        </div>
      </div>
      )}
    </main>
  );
};

export default RedirectTremendous;
