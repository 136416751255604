import React from 'react'
import spinner from '../assets/spinner.gif'

type Props = {}

const Spinner = (props: Props) => {
  return (
    <>
        <img src={spinner} alt="Loading...."  style={{ width: '30px', display: 'inline', paddingLeft: '5px', marginTop: '5px' }} />
    </>
  )
}

export default Spinner