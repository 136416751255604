import style from './style.module.scss';
import React from 'react';
import type { CSSProperties } from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { useMediaQuery } from 'react-responsive';

const FAQ = () => {
  const isPhoneScreen = useMediaQuery({
    query: '(max-width: 768px)'
  })

const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
  {
    key: '1',
    label: 'What is YesReferral?',
    children: <p className={style.discriptionTxt}>YesReferral offers a user-friendly referral program software that helps business owners 
    grow their business by leveraging their staff and clients to attract new clients or job 
    candidates through word-of-mouth marketing.</p>,
    style: panelStyle,
  },
  {
    key: '2',
    label: 'How does your 7 day free trail work?',
    children:<p className={style.discriptionTxt}>You can try the YesReferral software for 7 days, free of charge. After the 7-day trial 
    period, you will be automatically billed the monthly subscription fee. There are no 
    contracts, and you can cancel on the site at any time.</p>,
    style: panelStyle,
  },
  {
    key: '3',
    label: 'Are there any additional fees for sending rewards?',
    children: <p className={style.discriptionTxt}>No, there are no additional fees for electronically sending gift cards or prepaid Visa 
    cards- just pay the amount of the reward for a successful referral.
    
    For example, if the reward is $25, then all you’ll pay is $25 for the reward.
    
    And there are no redemption fees for your reward recipients. <br /> <br />
    
    *When funding your account to send rewards, bank transfers are always free. However, 
    if you use a credit card, a non-refundable 3% processing fee applies. When rewarding 
    your referrers, sending electronic gift cards is always free, but minimal bank fees may 
    apply if you select other reward options, such as ACH Bank Transfers, Venmo, or 
    PayPal. If you choose to ship a physical gift card, shipping fees will apply.</p>,
    style: panelStyle,
  },
  {
    key: '4',
    label: 'What is considered a "successful referral?"',
    children: <p className={style.discriptionTxt}>With YesReferral, you can reward both current clients and staff once a referral for new 
    clients or job candidates is successful. You decide what factors mean success to you, 
    such as when a candidate referral is hired or when a new client signs up for services. In 
    your dashboard, you can approve or deny the referral based on whether the referral 
    meets your defined criteria.</p>,
    style: panelStyle,
  },
];
  // const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom:isPhoneScreen?25: 40,
    border:"3px solid #dbdbdb",
    padding:isPhoneScreen?"0.5rem 1rem":"1rem 2rem"
  };
  return <section className={style.faqContainer}>
    <h1>FAQ</h1>
    <h6>Frequently Asked Questions</h6>
    <div className={style.faqAccordionWrapper}>
    <Collapse
      bordered={false}
      defaultActiveKey={[]}
      expandIcon={({ isActive }) => isActive?<FaMinus className={style.accordionIcon} />:<FaPlus className={style.accordionIcon} />}
      style={{ width:"100%",backgroundColor:"#fff"}}
      items={getItems(panelStyle)}
      expandIconPosition="end"
      
    />
    </div>

  </section>
};

export default FAQ;
