import { Button } from 'react-bootstrap';

const TremendousButton: React.FC = () => {
  return (
    <div className="text-center">
      <Button 
        variant="primary" 
        href="https://app.testflight.tremendous.com/apps/" 
        target="_blank" 
        rel="noopener noreferrer">
        Go to Tremendous
      </Button>
    </div>
  );
};

export default TremendousButton;
