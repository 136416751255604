import React, {  useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store/store';
import { User} from '../../app/features/auth/AuthSlice';
import { Col, Container, Row } from 'react-bootstrap';
import './home.css';
import { Spinner} from 'react-bootstrap';
import { redirectToSubscription } from '../../utils/functions/subscription';

type Props = {}

const Home = (props: Props) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [isLoading,setIsLoading]=useState(false)
  const userDetails = user as User;

  if(!userDetails){
    return <div style={{height:"90vh"}} className="d-flex justify-content-center align-items-center mt-2">
    <Spinner style={{color:"#655baa"}} animation="border" role="status" ><span className="sr-only"></span></Spinner>
    </div>
  }

  return (
    <div style={{display: "block", marginTop: "20%"}}>
      {
        !['active', 'trialing'].includes(userDetails?.subscription_status) ?
          <Container className="mt-5">
            <Row className="justify-content-center align-items-center">
              <Col md={6} className="text-center">
         
                {
                  isLoading?<Spinner animation="border" role="status"><span className="sr-only"></span></Spinner>:    <p className="text-danger">
                  You have not subscribed to this product. To subscribe click&nbsp;
                  <span
                    className="hover-text"
                    onClick={()=>{setIsLoading(true);
                      redirectToSubscription({setIsLoading})}}
                  >
                    here
                  </span>
                </p>
                }
            
              </Col>
            </Row>
          </Container>
        : <Container className="mt-5">
            <Row className="justify-content-center align-items-center">
              <Col md={6} className="text-center">
                <p className="text-primary">
                  Great! You have subscribed to this product.
                </p>
              </Col>
            </Row>
          </Container>
      }
    </div>
  )
}

export default Home;
