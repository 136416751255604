import { CAMPAIGN_API, REWARD_API } from "../../../utils/constants/apis.urls";
import { Campaign } from "../../../pages/Campaign/Campaign";
import { axiosInstance } from "../../../config/interceptor";

export const createCampaign = (data: Campaign) => {
  return axiosInstance.post(CAMPAIGN_API.CREATE_CAMPAIGN, data)
    .then(res => res)
    .catch(error => {
      let statusCode = null;

      if (error.response && error.response.status === 422) {
        statusCode = 422;
      } else if (error.response && error.response.status) {
        statusCode = error.response.status;
      } else {
        console.error("Error creating campaign:", error.message);
      }
      // eslint-disable-next-line
      throw { statusCode };
    });
};


export const fetchCampaignData = (userId: number, id: string) => {
  if (id !== "") {
    return axiosInstance.get(CAMPAIGN_API.FETCH_CAMPAIGN_DATA_REFERRAL.replace(':id', id))
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching campaign data:", error);
        throw error;
      });
  } else {
    return axiosInstance.get(CAMPAIGN_API.FETCH_CAMPAIGN_DATA.replace(':user_id', userId.toString()))
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching campaign data:", error);
        throw error;
      });
  }
};

export const deleteReferral = (referred_id: string) => {
  return axiosInstance.delete(CAMPAIGN_API.DELETE_CAMPAIGN_REFERRAL.replace(':referred_id', referred_id))
    .then(res => res)
    .catch(error => {
      console.error("Error creating campaign:", error);
      throw error.response?.data?.message || 'Please try again';
    });
};

export const sendReward = (campaign_id: number, referred_id : number, user_id: number, received_by:string, received_by_emailId: string, reward_amount:number) => {
  const data = {
    received_by: received_by,
    received_by_emailId: received_by_emailId,
    reward_amount: reward_amount,
    campaign_id: campaign_id,
    user_id: user_id.toString(),
    referred_id: referred_id.toString()
  };
  return axiosInstance.post(REWARD_API.SEND_REWARD, data)
    .then(res => res.data)
    .catch(error => {
      if(error.response.status===402){
        throw error.response?.data.error || 'Please try again';
      }
      else{
        throw error.response?.data?.message || 'Please try again';
      }
    });
};

export const denyReward = (referral_id: number, denyReason: string) => {
  const data = {
    deny_msg: denyReason
  };
  const url = REWARD_API.DENY_REWARD.replace(':referral_id', referral_id.toString());
  return axiosInstance.post(url,data)
    .then(res => res.data)
    .catch(error => {
      console.error("Error sending reward:", error);
      throw error.response?.data?.message || 'Please try again';
    });
};

export const checkCampaignName = (campaign_name: string) => {
  const url = CAMPAIGN_API.CHECK_CAMPAIGN_NAME;
  return axiosInstance.post(url,{campaign_name})
    .then(res => res.data)
    .catch(error => {
      console.error("Error sending reward:", error);
      throw error.response?.data?.message || 'Please try again';
    });
};