import style from './style.module.scss';
import subSection from '../../images/subSectionImage.png';
import heroImage from '../../images/heroImage.png';
import docCheckIcon from '../../images/docCheckIcon.png';
import mailIcon from '../../images/mailIcon.png';
import rocketIcon from '../../images/rocketIcon.png';
import goalImage from '../../images/goal.png';
import webAppImage from '../../images/webApp.png';
import paintBrushBg from '../../images/paintBrush.png';
import starsOnBg from '../../images/stars.png';
import arrowImage from '../../images/arrow.png';
import bubbleImage from '../../images/bubbles.png';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    const howItWorksSteps=[
        {
            icon:docCheckIcon,
            text:"Seeking new clients or job applicants? Kickstart your referral campaign by filling in a few details on a pre-filled template.",
        },
        {
            icon:mailIcon,
            text:"Engage your network effortlessly. Send your campaign to selected clients or your employees with a single click.",
        },
        {
            icon:rocketIcon,
            text:"Watch your business grow as motivated clients and employees share their unique referral links with family and friends, unlocking rewards for each referral that converts into new clients or new hires.",
        },
    ]
  return <>
  <section className={style.heroContainer}>
    <div className={style.leftContainer}>
        <div className={style.textContainer}>
        <h1>Supercharge</h1>
        <h2>Your Business Growth</h2>
        <p>Run referral campaigns and let your happy customers and team drive business growth for you!</p>
        <Link to="/pricing">  <button className={style.signupButton}>Start For Free!</button></Link>
        </div>
      

    </div>
    <div className={style.rightContainer}>
        <div className={style.imageContainer}>
        <img src={heroImage} alt="hero-illustrator" />
        </div>

</div>

  </section>
  <section className={style.howItWorks}>
    <h2>How It Works</h2>
    <h5>YesReferral is for busy business owners who want to effortlessly grow their business.</h5>
    <div className={style.stepsContainer}>
        {
            howItWorksSteps.map((elm,i)=>  <div key={`fuasdkf${i}`} className={style.stepElm}>
        <div className={style.iconElm}><img src={elm.icon} alt="" /></div>
        <h4>Step {i+1}</h4>
        <h6>{elm.text}</h6>
        </div>)
        }
      

    </div>
  </section>
  <section className={style.subSectionContainer}>
    <div className={style.leftContainer}>
        <div className={style.textContainer}>
            <div className={style.arrowImage}><img src={arrowImage} alt="" /></div>
        <h2>Drive Growth With <br /> Referrals</h2>
        <p>Stop wasting time and money on ads. <br /> YesReferral helps you get quality leads <br />directly from your network!</p>
        <Link to="/pricing"> <button className={style.signupButton}>Start For Free!</button></Link>
        </div>
      

    </div>
    <div className={style.rightContainer}>
       <div className={style.bubbleImage}> <img src={bubbleImage} alt="" /></div>
        <div className={style.imageContainer}>
            <img src={subSection} alt="subsection-illustrator" />
        </div>

</div>

  </section>
  <section className={style.featureContainer}>
    <div className={style.featureElmWrapper}>
         <div className={style.featureElm}>
         <div className={style.featureIcon}><img src={goalImage} alt="" /></div>
    <div className={style.featureText}>
        <h3>Streamline Your Referral Strategy</h3>
        <p>Easily run targeted referral campaigns with frictionless sharing, nudge reminders, & rewards referrers actually care about. 
</p>
    </div>
    </div>
    </div>
    <div className={style.featureElmWrapper}>
         <div className={style.featureElm}>
    <div className={style.featureIcon}><img src={webAppImage} alt="" /></div>
    <div className={style.featureText}>
        <h3>Manage Referral Tracking, <span className={style.paintBrushBg}><img src={paintBrushBg} alt="" /> <span className={style.paintBrushTxt}>Seamlessly</span> </span> </h3>
        <p>Easily track the performance of your campaign such as new leads received, who referred, manage rewards and more from a single dashboard.
</p>
    </div>
    </div>
    </div>
  </section>
  <section className={style.leadsContainer}>
    <div className={style.starBgImg}>
        <img src={starsOnBg} alt="" />
    </div>
<div className={style.leadsTxtWrapper}>
<h2>Getting more leads doesn’t have to be hard with YesReferral!</h2>
<h5>Try for free for 7 days. Cancel anytime!</h5>
<Link to="/pricing"><button className={style.signupButton}>Get Started!</button></Link>
</div>

  </section>

  </> 
};

export default LandingPage;
