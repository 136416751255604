import React, { useEffect, useState } from 'react'
import SubscriptionDetails from './components/SubscriptionDetails'
import BillingHistory from './components/BillingHistory';
import { fetchPaymentData } from '../../../../app/services/Subscription/paymentData';
import { PaymentData } from './components/PaymentData';
import Cookies from 'js-cookie';

type Props = {}

const SubscriptionSettings = (props: Props) => {
  // const userId = (user as User)?.id;
  const userId = parseInt(Cookies.get('user_id') || '0', 10);
  const [paymentData, setPaymentData] = useState<PaymentData[] | null>(null);
    
    useEffect(() => {
        fetchPaymentData(userId).then((res) => {
            setPaymentData(res.data);
          }).catch((error) => {
            console.log("error", error);
          });
    // eslint-disable-next-line 
    }, []);

  return (
    <div>
      <div className='mt-3'>
        <SubscriptionDetails paymentData={paymentData} />
      </div>
      <div className='mt-4'>
          <BillingHistory paymentData={paymentData} />
      </div>
      </div>
  )
}

export default SubscriptionSettings