import axios from "axios";
import { UserData } from "../../pages/AddClientsEmployees/ClientsEmployees";
import { CLIENTEMPLOYEE_API } from "./../../utils/constants/apis.urls";
import { axiosInstance } from "../../config/interceptor";

export const uploadCSV = async (file: File, userId: number | undefined, type: 'Client' | 'Employee') => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    const accessToken = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const res = await axios.post(CLIENTEMPLOYEE_API.UPLOAD_CSV + `${userId}/`, formData, config);
    return res.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || 'An unknown error occurred';
    console.log("Inside catch block error:", errorMessage);
    throw new Error(errorMessage);
  }
};

export const fetchDataByUserId = async (userId: number, emp_cli_id: number, type:string) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    let res;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    if(emp_cli_id!==0){
      res = await axios.get(CLIENTEMPLOYEE_API.GET_EMPLOYEECLIENT_BY_EMPCLIID + `${userId}/${emp_cli_id}`, config);
    }
    else if(type!==''){
      res = await axios.get(CLIENTEMPLOYEE_API.GET_EMPLOYEESCLIENTS + `${userId}/${type}`, config);
    }
    else{
      res = await axios.get(CLIENTEMPLOYEE_API.GET_EMPLOYEECLIENT_BY_USERID + `${userId}`, config);
    }
    return res.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.detail || 'An unknown error occurred';
    console.error("Inside catch block error :", errorMessage); 
    throw new Error(errorMessage);
  }
};

export const deleteEmployeeClient = async (emp_cli_id: number) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const res = await axios.delete(CLIENTEMPLOYEE_API.DELETE_EMPLOYEECLIENT + `${emp_cli_id}`, config);
    return res.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.detail || 'An unknown error occurred';
    console.error("Inside catch block error :", errorMessage); 
    throw new Error(errorMessage);
  }
};

export const editEmployeeClient = async (emp_cli_id: number, editedUserData: UserData) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const res = await axios.put(CLIENTEMPLOYEE_API.EDIT_EMPLOYEECLIENT + `${emp_cli_id}`, editedUserData, config);
    return res.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.detail || 'An unknown error occurred';
    console.error("Inside catch block error :", errorMessage); 
    throw new Error(errorMessage);
  }
};


export const manualUpload = async (first_name:string, last_name:string, type:string, email:string, phone_number: string) => {
  const data = {
    first_name: first_name,
    last_name: last_name,
    type: type,
    email: email,
    phone_number: phone_number
  };
  return axiosInstance.post(CLIENTEMPLOYEE_API.MANUAL_ADD, data)
    .then(res => res.data)
    .catch(error => {
      if(error.response.status === 400) {
        throw error.response?.data.errors || 'Please try again';
      } else {
        throw error.response?.data?.message || 'Please try again';
      }
    });

  };