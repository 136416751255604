import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert } from 'react-bootstrap';
import { fetchBalanceDetail, getRewardHistory } from '../../app/services/Reward/rewardDashboardService';
import '../../style/common.css';
import './RewardDashboard.css';
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { formatDateDefault } from '../../utils/functions/timeConversion';

interface RewardHistoryItem {
  id: string;
  order_id: string;
  created_at: string;
  value: {
    denomination: number;
    currency_code: string;
  };
  delivery: {
    method: string;
    status: string;
  };
  recipient: {
    email: string;
    name: string;
    phone: string;
  };
}

interface RewardHistoryResponse {
  rewards: RewardHistoryItem[];
  total_count: number;
}

const RewardDashboard = () => {
  const [rewardHistory, setRewardHistory] = useState<RewardHistoryItem[]>([]);
  const [balance, setBalance] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const user_id = parseInt(Cookies.get('user_id') || '0', 10);

  useEffect(() => {
    getRewardHistory(user_id)
      .then(response => {
        const data: RewardHistoryResponse = response.data;
        if (Array.isArray(data.rewards)) {
          setRewardHistory(data.rewards);
        } else {
          notification.error({
            message: 'Error',
            description: 'Unexpected response format for reward history.',
          });
        }
        setLoading(false);
      })
      .catch(err => {
        notification.error({
          message: 'Error',
          description: err.message,
        });
        setError(err);
        setLoading(false);
      });
  }, [user_id]);
  
  useEffect(() => {
    fetchBalanceDetail(user_id)
      .then(response => {
        const balanceData = response.data.funding_sources[0].meta.available_dollars;
        setBalance(balanceData / 100);
      })
      .catch(err => {
        notification.error({
          message: 'Error',
          description: err.message,
        });
        setError(err);
        setLoading(false);
      });
  }, [user_id]);

  if (loading) {
    return <div style={{height:"80vh"}} className="d-flex justify-content-center align-items-center">
    <Spinner style={{color:"#655baa"}} animation="border" role="status" ><span className="sr-only"></span></Spinner>
    </div>
  }

  if (error) {
    return <Alert variant="danger">Error loading data: {error.message}</Alert>;
  }

  return (
    <div className="reward-dashboard">
      <h1 className="text-center my-4">Reward History</h1>
      <div className="table-wrapper">
        <strong><h3>Available balance: ${balance}</h3></strong>
        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Funding Status</th>
              <th>Created At</th>
              <th>Recipient Email</th>
              <th>Recipient Name</th>
              {/* <th>Phone</th> */}
            </tr>
          </thead>
          <tbody>
            {rewardHistory.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.order_id}</td>
                <td>{item.value.denomination} {item.value.currency_code}</td>
                <td>{item.delivery.status}</td>
                <td>{formatDateDefault(item.created_at)}</td>
                <td>{item.recipient.email}</td>
                <td>{item.recipient.name}</td>
                {/* <td>{item.recipient.phone}</td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RewardDashboard;
