export const formatDate = (timestamp: number, type: string): string => {
    const date = new Date(timestamp * 1000);
    let options: Intl.DateTimeFormatOptions = {};
    if (type === 'payment_date') {
        options = {
            year: 'numeric',
            month: 'long',
            day: '2-digit'
        };  
    }
    else if (type === 'next_payment_date') {
        options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        };
    }
    return date.toLocaleDateString('en-US', options);
};


export const formatDateDefault = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };