import { Dispatch } from 'redux';
import { loginUser } from '../../app/services/UserServices';
import { initializeAuth } from '../../app/features/auth/AuthSlice';
import { LoginData } from '../../pages/Login/LoginType';

export const handleUserLogin = async (dispatch: Dispatch, userData: LoginData) => {
  try {
    const res = await loginUser(userData);
    if (res.status === 200) {
      dispatch(initializeAuth({ access: "", refresh: "", user: res.data.user }));
      return res.data.user;
    }
  } catch (error) {
    console.error("Error during login: ", error);
    throw error;
  }
};