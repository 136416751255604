import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface SubscriptionPopupProps {
  show: boolean;
  handleClose: () => void;
}

const SubscriptionPopup: React.FC<SubscriptionPopupProps> = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Subscription Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your subscription is inactive. Please subscribe to access this feature.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionPopup;
