import React, { useEffect, useState } from 'react'
import { redirectToSubscription } from '../../utils/functions/subscription';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import {LogoutOutlined } from '@ant-design/icons';

const SubscriptionRedirectLoader = () => {
    const [isError,setIsError]=useState(false);
    const navigate = useNavigate();
    useEffect(()=>{   
        redirectToSubscription({setIsError,afterAuth:true})
    },[])

    const handleLogout = async () => {
        try {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          Cookies.remove("user_id");
          Cookies.remove("user_email");
          Cookies.remove("userin");
          Cookies.remove("subscription_redirecting");
          navigate("/user/login");
        } catch (error) {
          console.error("Logout error:", error);
        }
      };
  return (
    <main className="w-100 d-flex justify-content-center align-items-center flex-column" style={{ minHeight: '100vh' }}>
        {
            isError?<> <div className="alert alert-danger" role="alert">
            There's some problem while creating your session please login and try again after some time
        </div> <Button type="primary"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              className="btn btn-primary">Logout</Button></>:

            <p>Please wait while we are redirecting you to the subscription page</p>
           
        }
       
    
  </main>
  )
}

export default SubscriptionRedirectLoader