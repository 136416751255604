import 'react-phone-number-input/style.css';

import PhoneInput from 'react-phone-number-input';
interface PhoneNumberProps {
  value: string | undefined; 
  setValue: (value: string | undefined) => void;
  readOnly?:boolean 
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ value, setValue,readOnly }) => {



const CustomCountrySelect = () => {
  return null; // This component renders nothing, hiding the country select dropdown
};
  return (
    <PhoneInput
    defaultCountry="US"
      value={value && value.charAt(0)==='+'?value:`+${value}`}
      onChange={setValue}
      numberInputProps={{
        className: 'form-control'
      }}
      countrySelectComponent={readOnly?CustomCountrySelect:undefined}
      readOnly={readOnly?true:false}
    />
  );
};

export default PhoneNumber;