import React from 'react'
import { Table } from 'react-bootstrap';
import { FiDownload } from 'react-icons/fi';
import './billing.css';
import { formatDate } from '../../../../../utils/functions/timeConversion';
import { BillingHistoryData } from './BillingHistoryData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store/store';

const BillingHistory: React.FC<BillingHistoryData> = ({ paymentData }) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const handleInvoiceDownload = (url: string) => {
      window.location.href = url;
  };
  return (
    <>
      <h5 style={{ fontWeight: 700 }}>Billing history</h5>
      {
        (paymentData && paymentData.length > 0) &&
          <div className="custom-table-container mt-4">
              <Table striped bordered hover className="custom-table">
                  <thead>
                      <tr>
                          <th style={{ width: '40%', paddingLeft: '20px', paddingTop: '10px', paddingBottom: '17px' }}>
                          Payment date
                          </th>
                          <th style={{ width: '20%', paddingTop: '10px', paddingBottom: '17px' }}>
                          Amount
                          </th>
                          <th style={{ width: '40%', paddingTop: '10px', paddingBottom: '17px' }}>
                          Invoice file
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      {
                          paymentData && paymentData.map((payment, index) => (
                              <tr key={index}>
                                  <td className='first-cell'>{formatDate(payment.created_at, 'payment_date')}</td>
                                  <td className='middle-cell'>${user?.subscription_status === 'trialing'
                                    ? (0).toFixed(2)
                                    : (Math.round(payment.amount * 100) / 100).toFixed(2)}</td>
                                  <td className='last-cell'><FiDownload className='invoice-download-btn' onClick={() => handleInvoiceDownload(payment.invoice_url)} /></td>
                              </tr>
                          ))
                      }
                  </tbody>
              </Table>
          </div>
      }
    </>
  )
}

export default BillingHistory