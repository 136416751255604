import { Card, Container } from 'react-bootstrap';

const TremendousPage = () => {
    return (
        <Container className="d-flex justify-content-center align-items-center">
            <Card className="text-center" style={{ maxWidth: '600px', width: '100%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', margin: '2rem'}}>
                <Card.Body style={{ margin: '30px' }}>
                            <h4>WE’RE HERE TO HELP</h4>
                            <hr />
                            <h5>Tremendous support</h5>
                            <p>
                                For help with account management, contact: <a href="mailto:clients@tremendous.com">clients@tremendous.com</a>
                            </p>
                            <hr />
                            <h5>YesReferral support</h5>
                            <p>
                                For any queries, contact: <a href="mailto:support@yesreferral.com">support@yesreferral.com</a>
                            </p>
                            <hr />
                            <h5>Tremendous help articles</h5>
                            <p>Visit the 
                                <a href="https://help.tremendous.com/en/articles" target="_blank" rel="noopener noreferrer"> Tremendous Help Center</a> for detailed articles.
                            </p>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default TremendousPage;
