import style from './style.module.scss'

const TermsConditions = () => {
  return <section className={style.container}>
    <h1>Terms Of Service </h1>
    <h6>Effective July 10, 2024</h6>
    <div className={style.textContainer}>
    <p>Welcome to YesReferral! This Terms of Service ("<span className={style.boldText}>Terms</span>") is an 
      agreement between you (the "<span className={style.boldText}>User</span>", "<span className={style.boldText}>You</span>" or "<span className={style.boldText}>Your</span>") and YesReferral 
      ("<span className={style.boldText}>YesReferral</span>", "<span className={style.boldText}>we</span>", "<span className={style.boldText}>us</span>" or "<span className={style.boldText}>our</span>"). Please read these Terms carefully 
       before using our Services.
       </p>
       <p>
        A Business Owner ("<span className={style.boldText}>Business Owner</span>" "<span className={style.boldText}>User</span>", "<span className={style.boldText}>You</span>" or "<span className={style.boldText}>Your</span>") are each person that accesses or utilizes the site or services
       </p>
       <p>The YesReferral Services ("<span className={style.boldText}>Services,</span>" "<span className={style.boldText}>Service</span>" or "<span className={style.boldText}>Site</span>") means 
            any websites, applications, and other services under the control of 
            YesReferral, whether partial or otherwise</p>
        <p>
        The term "<span className={style.boldText}>Referrers</span>" collectively refers to any individual, such as 
your employees, clients, or any other person engaged by you to 
participate in referral activities through the Services.
        </p>
        <p>
        A "<span className={style.boldText}>Referral</span>" is an individual who has received a unique referral link or 
has been directed by your Referrers to engage with your company. 
This engagement may involve signing up for services, submitting 
information for inquiries, requesting additional details, or any other 
interaction with you or your company.
        </p>
        <p>
        Your use of YesReferral in any way means that you agree to all of 
these Terms. These Terms include the provisions in this document, as 
well as those in the Privacy Policy. We reserve the right to change the Terms at any time and will notify you via notice on the YesReferral site, through email and/or by other 
means.
        </p>
        <p>
        If you use the Service in any way after a change to the Terms is 
effective, then this means you agree to all of the changes.
        </p>
        <p>
        Please read the following Terms of Service carefully. These Terms 
govern your access and use of YesReferral, it’s Services and 
Collective Content (defined below) and constitute a binding legal 
agreement between you and YesReferral.
        </p>
        <p>
        You acknowledge and agree that, by accessing or using the 
YesReferral Services, or by downloading, uploading, entering or 
posting any content from or on YesReferral or through the YesReferral 
Services, you are indicating that you have read, and that you 
understand and agree to be bound by these Terms whether or not you 
have registered on YesReferral.
        </p>
        <h5>
        If You Do Not Agree To These Terms Then You May Not Use The 
YesReferral Services. 
        </h5>
        <p>
        YesReferral provides a subscription-based platform for Business 
Owners to create referral campaigns and allows you to incentivize 
their Referrers to refer new Referrals. The Services may allow you to 
enter, upload, search, save, store and manage Referrer related 
information and files, create referral campaigns, send mass emails to 
Referrers, and track new referrals through unique referral links.
        </p>
        <h5>
        YesReferral Cannot Guarantee That New Referrals Will Be 
Obtained Through Use Of Its Service. 
        </h5>
        <h3>Account Registration</h3>
        <p>
        To access features of the Services, you will be required to register for 
an account with YesReferral. You agree to provide accurate, current, 
and complete information during the registration process and to 
update such information to keep it accurate, current, and complete. 
When signing up on the Site, you agree to receive email 
communication from YesReferral. 
        </p>
        <h3>Third-Party Services</h3>
        <p>
        The Services will link to third-party websites or resources that are not 
owned or controlled by YesReferral for explicit use of allowing you to 
issue rewards or send emails to Referrers and their Referrals. You 
acknowledge and agree that YesReferral assumes no responsibility 
and is not liable for the availability, security, accuracy, content, or 
policies of such websites or resources. You understand and agree that 
use of Third Party Services is at your own risk and that YesReferral is 
not liable or not responsible for the privacy, business practices, charges or other 
policies of Third Party Services. 
        </p>
        <h3>Gift Card Rewards</h3>
        <p>
        YesReferral uses <a href="https://www.tremendous.com/" target='_blank'>Tremendous</a> , a Third-Party Service, to create an 
escrow account for Gift Card Payouts and to send electronic gift card 
rewards to Referrers. During the account creation process with 
YesReferral, you will be redirected to the Tremendous site to set up 
your Tremendous account. You will be subject to the terms, conditions 
and privacy policies of Tremendous (<a href="https://www.tremendous.com/
terms/" target='_blank'>https://www.tremendous.com/
terms/</a> ) in addition to these Terms. To distribute rewards, you must 
create a Tremendous account and ensure your account is sufficiently 
funded to issue the rewards to your Referrers. Please visit 
Tremendous Help Center for additional questions and support: <a href="https://
help.tremendous.com/en/articles" target='_blank'>https://
help.tremendous.com/en/articles</a> 
        </p>
        <h3>Referral Campaigns</h3>
        <p>
        Business owners can create referral campaigns on YesReferral 
specifying the type of referrals they are seeking, campaign rules, and 
reward details. Referrers are provided with unique referral links to 
share with their potential Referrals. Referrals are tracked through 
these links, and once a Referral is approved by you, rewards are 
issued to the Referrer by use of third-party services.
        </p>
        <h3>Email Policy</h3>
        <p>
        All users must send only permission-based emails. You may only 
import email addresses from those who previously opted-in through 
another channel. Adding email addresses without permission, using 
unrelated subscription forms, or using purchased, rented, or borrowed 
lists is prohibited. 
You are not allowed to use YesReferral to send unsolicited emails 
without a prior business relationship. 
YesReferral reserves the right to suspend or terminate access to your 
account and all related data without notice if any of our usage rules 
are violated or for any other valid reason determined by YesReferral. 
        </p>
        <h3>Collective Content</h3>
        <p>
        The contents of the YesReferral Services include: designs, text, 
graphics, images, video, information, logos, button icons, software, 
documents, computer code, and other content (collectively, 
  "<span className={style.boldText}>Collective Content</span>" or "<span className={style.boldText}>Content</span>"). All Collective Content and the 
compilation (meaning the collection, arrangement, and assembly) of 
all Collective Content are the property of YesReferral and its licensors 
and are protected under copyright, trademark, and other laws. 
        </p>
        <p>
        Users are NOT permitted to access or use any YesReferral Services 
or Content for the purpose of building a similar or competitive product 
or service; perform any vulnerability, penetration or similar testing of 
the YesReferral Services; modify the YesReferral Services, reverse 
engineer, de-compile, or disassemble the YesReferral Services; or use 
or access the YesReferral Services in violation of any applicable law 
or intellectual property right. 
        </p>
        <p>
        YesReferral Content are or may be Trademarks and copyright and can 
not be used or reproduced without our prior written permission. For 
permission requests please contact us at: <a href="mailto:support@yesreferral.com">support@YesReferral.com</a> 
        </p>
        <p>
        We grant you a limited, non-exclusive, non-transferable license, 
subject to these Terms, to access and use the YesReferral Services 
for your professional and commercial purposes, at our discretion. We 
have the right to revoke this license at any time without notice and 
with or without cause
        </p>
        <h3>Security</h3>
        <p>
        You are responsible for maintaining the confidentiality of your account 
password. Information contained within your account is as accurate as 
the data supplied by the User. Therefore, we recommend you consider 
carefully whether to permit any other User access to your account 
information. YesReferral does not assure the accuracy, reliability, 
confidentiality or security of information.
        </p>
        <h5>
        You are solely responsible for all activities that occur under your 
account, password, and username – whether or not you 
authorized the activity. 
        </h5>
        <p>You are responsible for monitoring the Collective Content uploaded or 
entered, whether uploaded by the you or by other Users including 
Third Party Services. You agree to notify us immediately of any 
unauthorized use of your account at: <a href="mailto:support@yesreferral.com">support@YesReferral.com</a> . We 
reserve the right to close your account at any time for any or no 
reason without prior notice to you including any reason that we deem 
warrants such action, which includes your breach of any provision of 
this agreement. We assume no liability for closing your account for 
any or no reason. Please note that you, and not YesReferral, are 
responsible for maintaining and protecting your account and Collective 
Content. Although we strive to protect the privacy of your personal 
information, unfortunately we cannot guarantee complete security.</p>
<p>YesReferral will not be liable to you for any loss, damage or corruption 
of any kind to your account and Collective Content, or for any costs or 
expenses associated with backing up or restoring your Collective 
Content or accounts and/or User claims relating to your Collective 
Content.</p>
<h3>Compliance With Laws</h3>
<p>
It is your responsibility to be aware of and follow the laws and 
regulations of your state regarding the offering of referral fees, 
particularly within certain industries, or taxes on rewards paid to 
Referrers after a certain payment threshold is met. You are 
responsible for knowing and abiding by these laws when using the 
YesReferral Services. YesReferral is not responsible for ensuring your 
compliance with applicable laws.
</p>
<h5>
If your use of the Services is prohibited in any way by applicable 
laws, you are not authorized to use the Site. Any unlawful or 
inappropriate conduct, act, or omission by you constitutes a 
violation of these Terms and may result in the immediate 
termination of your account and access to the Services.
</h5>
<h3>Privacy</h3>
<p>Please visit our <a href="/privacy-policy">Privacy Policy</a> for more information.</p>
<h4>Interactions and Disputes</h4>
<p>
YesReferral is not responsible for or liable in any manner for the actions or behavior of any Users, Referrers, Referrals, Individuals, or Third-Party Services (collectively referred to as "Other Parties") online or offline. You are solely responsible for your interactions with Other Parties on the Site, whether online or offline.
</p>
<p>
We reserve the right, but are not obligated, to monitor or become involved in disputes between you and Other Parties, including disputes involving third-party sites.
</p>
<p>
To the extent permitted by law, you expressly agree that YesReferral and its subsidiaries and affiliates, including their respective shareholders, owners, employees, representatives, officers, directors, and contractors (collectively, the "YesReferral Team"), shall not be liable for any claims, demands, damages, litigation, rights of action, or causes of action, including, but not limited to, injury, theft, or loss of property, whether present or future, known or unknown, anticipated or unanticipated, that result from or arise out of your use of the Site or Services, or any claims brought by third parties related to your use of the Site or Services (collectively, "Claims"). You hereby fully and forever release and discharge YesReferral and the YesReferral Team from any and all Claims.
</p>
<p>
YOU ACKNOWLEDGE AND AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE AND EXCLUSIVE RISK AND HEREBY EXPRESSLY RELEASE YESREFERRAL FROM ANY AND ALL LIABILITY FOR CONTROVERSIES, CLAIMS, SUITS, INJURIES, LOSS, HARM, AND/OR DAMAGES ARISING FROM OR RELATED TO THE SERVICES OR YOUR INTERACTIONS OR DEALINGS WITH OTHER PARTIES, REFERRERS, REFERRALS, AND THIRD PARTIES, INCLUDING ANY ACTS OR OMISSIONS ONLINE OR OFFLINE.
</p>
<h4>Eligibility</h4>
<p>To register and become a user of the Site, you must be at least 18 
years of age to use the Services. <span className={style.boldText}>
Any access to or use of the 
YesReferral Services by anyone under age 18 is expressly 
prohibited.
  </span>  </p>
<h4>Payment</h4>
<p>The subscription services include the respective features and 
functionalities in your plan as defined on the Pricing Page of the 
YesReferral website.</p>
<p>Access to services and features of the Service require a monthly 
subscription ("<span className={style.boldText}>Monthly Subscription</span>" or "<span className={style.boldText}>Subscription</span>") Upon 
registration for services, you agree to pay YesReferral the applicable 
Subscription charges and fees at the prices and term then in effect, 
and you authorize YesReferral to charge your chosen payment 
method in connection therewith. We use a third-party payment 
processor, Stripe (<a href="https://stripe.com/in" target="_blank">stripe.com</a> ) to bill you through a payment account 
linked to your account on our Services. The processing of payments 
will be subject to the terms, conditions and privacy policies of Stripe 
(<a href="https://stripe.com/legal/ssa" target="_blank">https://stripe.com/legal/ssa</a> ) in addition to these Terms. We are not 
responsible for any errors by Stripe. By choosing to use our services, 
you agree to pay us, through Stripe, all charges and Fees at the prices 
then in effect for any use of such Services in accordance with the 
applicable payment terms, and you authorize us, through Stripe, to 
charge your chosen payment provider (for example, a credit or debit 
card you provide). You agree to make payment using Stripe. We 
reserve the right to correct any errors or mistakes that the Stripe 
makes even if it has already requested or received payment.</p>
<p>With your recurrent monthly subscription, your payment method 
automatically will be charged at the start of each subscription period 
for the fees applicable to that period. If your subscription plan contains 
a free trial, your payment will be charged and your subscription will 
begin at the end of that free trial</p>
<p>We will store and continue billing your payment method (e.g. credit 
card) to avoid interruptions in your Services and to use to pay other 
Services you may buy in the future unless you cancel services.</p>
<h4>YesReferral Cancellation and Refunds </h4>
<p>If you cancel a monthly subscription service, you will have access to 
the Services until the end of the current subscription term. Monthly 
subscription and/or Services will not be renewed after that term 
expires, and your access to Services within the Site will be restricted.</p>
<p>If you wish to cancel your Subscription and avoid future charges, you 
must cancel before the renewal date. Subscription fees are non-
refundable, and you will not be eligible for a refund of any portion of 
the monthly subscription paid for the current or past monthly 
subscription service benefit period. We do not provide refunds for 
partially used Services, credit refunds, or prorated billing for 
subscriptions cancelled mid-month. In such circumstances, you will 
continue to have access to your subscription until the end of the plan’s 
billing cycle.</p>
<p>
<span className={style.boldText}>
If you decide to cancel your YesReferral subscription and there 
are still funds in your Tremendous escrow account, please see 
"Tremendous Cancellation" (as described below) to withdraw 
your funds. 
</span>

</p>
<p>
In case you have performed a credit card chargeback or otherwise 
reversed a payment to us, we may immediately restrict or terminate 
your account.
</p>
<p>
YesReferral, in its sole discretion, has the right to suspend or 
terminate your account, and refuse any and all current or future use of 
the Subscription Service, for any reason at any time. Such termination 
of the Subscription Service will result in the deletion of your Account. 
YesReferral reserves the right to refuse service to anyone for any 
reason at any time.
</p>
<p>
YesReferral is not responsible for, and will not reimburse, any fees 
incurred by you from your bank or other financial institution, including 
without limitation overdraft charges, insufficient funds charges, interest 
charges, or finance charges, which may have occurred as a result of 
charges billed by YesReferral or on behalf of Third Party Services.
</p>
<h4>
Tremendous Cancellation 
</h4>
<p>Once you cancel your subscription with YesReferral, you will be 
redirected to Tremendous or you can go directly to the <a href="https://www.tremendous.com/">Tremendous</a>  
site to access your account. </p>
<p>
YesReferral uses Tremendous to create an escrow account for Gift 
Card Payouts and to send electronic gift cards to Referrers. If you 
decide to cancel your YesReferral subscription and there are still funds 
in your Tremendous escrow account, you may withdraw the funds 
directly from the Tremendous Site back to your company by following 
their instruction here: <a href="https://help.tremendous.com/en/articles/return-your-account-balance">Withdraw Account Balance</a>  or contact the 
<a href="mailto:clients@tremendous.com">Tremendous support team</a>  to request a refund.
</p>
<h4>Changes</h4>
<p>
We reserve the right, at our sole discretion, to modify, discontinue or 
terminate the YesReferral Services or replace these Terms at any 
time. The modified version will be indicated by an updated “Revised” 
date at the top of this Terms notice. By continuing to access or use the 
YesReferral Services after we have posted a modification through the 
Service or have provided you with notice of a modification, you are 
indicating that you agree to be bound by the modified Terms. If the 
modified Terms are not acceptable to you, your only recourse is to 
cease using the YesReferral Services.
</p>
<p>
However, each time that you use the Service, you should check the 
date of the Terms (set forth above) and review any changes that have 
been made since you last utilized the Service. By continuing to utilize 
the Services after any such changes are posted, you will agree and 
consent to any such changes.
</p>
<h4>Warranties And Dispute Resolution </h4>
<p>
YESREFERRAL MAKES NO WARRANTY THAT YESREFERRAL 
SERVICES OR YESREFERRAL CONTENT WILL MEET YOUR 
REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, 
SECURE, OR ERROR-FREE BASIS. YOUR USE OF THE 
YESREFERRAL SERVICES IS AT YOUR SOLE RISK, AND THE 
YESREFERRAL SERVICES ARE PROVIDED ON AN “AS IS” AND 
“AS AVAILABLE” BASIS. YOU AGREE TO DEFEND, INDEMNIFY, 
AND HOLD HARMLESS YESREFERRAL, ITS OFFICERS, 
DIRECTORS, EMPLOYEES, AGENTS AND CONTRACTORS FROM AND AGAINST
ANY CLAIMS, LIABILITIES, DAMAGES, LOSSES AND EXPENSES, 
INCLUDING, WITHOUT LIMITATION, REASONABLE LEGAL AND 
ACCOUNTING FEES, ALLEGING, ARISING OUT OF OR RELATED 
TO ANY YESREFERRAL CONTENT OR ACCOUNTS AND 
RECORDS CREATED BY YOU, OTHER USERS, YOUR USE AND 
RELIANCE OF THIRD PARTY SERVICES AND/OR YOUR BREACH 
OF THESE TERMS.
</p>
<p>You agree to first contact YesReferral at <br />
<a href="mailto:support@yesreferral.com">support@YesReferral.com</a>  regarding any claim or controversy arising 
out of or relating to these Terms of Use or your use of the YesReferral 
Services. You and YesReferral agree to settle any disputes, claims or 
controversy regarding use of the YesReferral services through binding 
arbitration under the rules of the American Arbitration Association. Any 
such arbitration, to the extent necessary, shall be conducted in 
Atlanta, Georgia. You covenant not to sue YesReferral in any other 
forum. </p>
<p>
You also acknowledge and understand that if such disputes arise with 
YesReferral regarding your use of the YesReferral services or these 
Terms:
</p>
<ul>
  <li>You are giving up your right to have a trial by jury. </li>
  <li>You are giving up your right to serve as a representative, as a 
private attorney general, or any other representative capacity, or 
to participate as a member of a class of claimants in any lawsuit 
involving such disputes and</li>
<li>
You must file a claim within 1 (one) year after such claim arose or 
it is forever barred.
</li>
</ul>
<h4>Contact </h4>
<p>If you have any questions about these Terms, please contact 
YesReferral at <a href="mailto:support@yesreferral.com">support@YesReferral.com</a>  <br /><br /> Or by mail at: <br /><br />YesReferral <br />PO Box 18532 <br />Atlanta, GA 31126</p>
    </div>


  </section>
};

export default TermsConditions;
