import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../app/services/UserServices';
import Spinner from '../../components/Spinner';
import { ForgotPasswordData } from './ForgotPasswordType';
import logo from '../../images/yes-referral-logo.png'
import { notification } from 'antd';

const ForgotPassword = () => {
  // const [response, setResponse] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openNotification = (type: 'success' | 'error', message: string) => {
    notification[type]({
      message: type === 'success' ? 'Success' : 'Oops!',
      description: message,
      placement: 'topRight'
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const data: ForgotPasswordData = {
      email: e.target.email.value,
    };

    forgotPassword(data).then((res: any) => {
      setIsLoading(false);
      if (res.status === 202) {
        e.target.reset();
        openNotification('success', 'We have e-mailed your password reset link!');
      } else {
        openNotification('error', res);
      }
    }).catch((error:any)=>{
      setIsLoading(false);
      openNotification('error', String(error));
    });
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
          <div className="card p-4 border rounded shadow-sm border-gray" style={{width:'100%', maxWidth:'30rem'}}>
          
          <div className="mb-4">
          <img className='pb-4' src={logo} alt="Logo" style={{ width: '234px', height: 'auto' }}/>
            <h2>Reset your password</h2>
         </div>
            <form onSubmit={handleSubmit} style={{ margin: "15px 5px" }}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" name="email" id="email" required/>
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary" style={{ padding: "5px"}}>Send reset instructions</button>
                {isLoading && <Spinner />}
              </div>
            </form>
            <p className="mt-3 text-center">Don’t want to reset your password?  <Link to="/user/login" className="text-decoration-none link" >Sign in</Link></p>
          </div>
        </div>
  );
};

export default ForgotPassword;
