import React from 'react';
import { Container, Card } from 'react-bootstrap';
import './TremendousCustom.css';
import TremendousButton from './TremendousButton';

const AddFund: React.FC = () => {
  return (
    <Container className="container-custom">
      <Card className="card-custom">
        <Card.Body className="card-body-custom">
          <h1 className="text-center my-4">Add funds</h1>
          <div className='mb-3'>
            <h3 className="card-subtitle">To add funds, follow the steps below:</h3>
            <ol className="card-list">
              <li>Log in to your Tremendous account.</li>
              <li>Locate and click the 'Add Funds' button on the right side of the page.</li>
              <li>Choose your preferred payment method from the options available.</li>
              <li>Follow the instructions provided by Tremendous to Complete the payment process.</li>
              <li>Return to YesReferral.</li>
            </ol>
          </div>
          <TremendousButton />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddFund;
