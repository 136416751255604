import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { loginFail, loginPending, loginSuccess } from '../../app/features/auth/AuthSlice';
import { loginUser } from '../../app/services/UserServices';
import { RootState } from '../../app/store/store';
import { notification } from 'antd';
import { LoginData } from './LoginType';
import logo from '../../images/yes-referral-logo.png';
import '../../style/common.css';
import { getTremendousOAuth } from '../../app/services/Reward/OAuthService';
import Cookies from 'js-cookie';
import OverlayLoader from '../../components/OverlayLoader';

const Login = () => {
  const [searchParams] = useSearchParams();
  // eslint-disable-next-line
  const [authCode, setAuthCode] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      setAuthCode(code);

        getTremendousOAuth(code)
          .then(() => {
            notification.success({
              message: 'Success',
              description: 'Got it',
            });
          })
          .catch(error => {
          });

    }
    // eslint-disable-next-line 
  }, []);

  const openNotification = (message: string, description: string) => {
    notification.error({
      message: 'Oops!',
      description: description,
      placement: 'topRight',
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data: LoginData = {
      email: e.target.email.value,
      password: e.target.password.value,
      refreshFlag: false,
    };
    dispatch(loginPending());
    loginUser(data).then((res: any) => {
      if (res.status === 200) {
        dispatch(loginSuccess(res.data))
        const subscription_status=res.data.user.subscription_status
        if(subscription_status==="inactive"){
          Cookies.set("subscription_redirecting","true")
          navigate("/checkout-redirect");
        }else{
          navigate("/");
        }       
      } else {
        dispatch(loginFail(res));
        openNotification('Error', res);
      }
    }).catch((error:any)=>{
      dispatch(loginFail(String(error)));
      openNotification('Error', String(error));
    })

    e.target.reset();
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4 border rounded shadow-sm border-gray" style={{ width: '100%', maxWidth: '30rem' }}>
        <div className="mb-4">
        <Link to="/">
          <img className='pb-4' src={logo} alt="Logo" style={{ width: '234px', height: 'auto' }} /></Link>

          <h2>Login</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" name="email" required/>
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <Link to="/user/forgot-password" className="text-decoration-none text-gray link" style={{ fontSize: "0.85rem", marginLeft: "5px", float: "right" }}>Forgot password?</Link>
            <div className="input-group">
              <input type="password" className="form-control" id="password" name="password" required/>
            </div>
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </div>
          {
            isLoading? <OverlayLoader/>:<></>
          }

        </form>
        <div className="mt-3 text-center text-gray">
          <p>Don’t have an account? <Link to="/user/registration" className="text-decoration-none link">Sign up</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
